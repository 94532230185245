import React from 'react';
import './index.less';

const AlarmNumEchart = Loader.loadBusinessComponent('Statistics', 'AlarmNumEchart');
const AlarmTypeEchart = Loader.loadBusinessComponent('Statistics', 'AlarmTypeEchart');
const AlarmStateEchart = Loader.loadBusinessComponent('Statistics', 'AlarmStateEchart');

class AlarmEcharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList: {
        // keyPointCount: 0,
        // outsiderCount: 0,
        // privateNetCount: 0
      }
    };
    this.initData();
    this.getTypeData();
    this.countAlarmResultsByMonitorType();
  }
  initData() {
    SocketEmitter.on(SocketEmitter.eventName.resolveAlarm, this.handleDataUpdate);
  }
  handleDataUpdate = () => {
    this.getTypeData();
  };
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.resolveAlarm, this.handleDataUpdate);
  }
  getTypeData = () => {
  
  };

  countAlarmResultsByMonitorType = () => {
    const { requestAlarmTypes } = this.props;
    
    Service.statistics.countAlarmResultsByMonitorType({ alarmTypes: requestAlarmTypes }).then(res => {
      let { typeList } = this.state; 
      res.data.map(v => {
        if(v.alarmType === '1') {
          typeList.keyPointCount = v.count - 0
        }
        if(v.alarmType === '2') {
          typeList.outsiderCount = v.count - 0
        }
        if(v.alarmType === '5') {
          typeList.privateNetCount = v.count - 0
        }
      })
      this.setState({
        typeList
      })
    });
  }

  render() {
    const { typeList } = this.state;
    const { alarmLabel, requestAlarmTypes } = this.props;
    return (
      <React.Fragment>
        <div className="alarm-echarts" style={{ margin: 0 }}>
          警情处理
        </div>
        <div className="alarm-real-just">
          <div className="alarm_all_total_num">
            <div className="title" />
            <div className="content">
              <AlarmNumEchart requestAlarmTypes={requestAlarmTypes} />
            </div>
          </div>
          <div className="alarm_type_total_num">
            <div className="title" />
            <div className="content">
              <AlarmTypeEchart typeList={typeList} alarmLabel={alarmLabel} />
            </div>
          </div>
          <div className="alarm_change_total_num">
            <div className="title" />
            <div className="content">
              {<AlarmStateEchart requestAlarmTypes={requestAlarmTypes} alarmLabel={alarmLabel}/>}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AlarmEcharts;
