import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Tabs } from "antd";
import * as _ from "lodash";
import AlarmEcharts from "./component/AlarmEcharts";
import "./index.less";

const TabPane = Tabs.TabPane;
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");
const Loading = Loader.Loading;
const RefreshButton = Loader.loadBaseComponent("RefreshButton");
const RealAlarmList = Loader.loadBusinessComponent("MonitorHistoryAlarm", "RealAlarmList");

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("tab", "user")
@observer
class RealAlarmView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLists: {},
      loading: true,
      infinitKey: Math.random()
    };
    const sceneCode = BaseStore.user.appInfo.sceneCode;
    this.alarmLabel = Dict.map.monitorLabel[sceneCode];
    let requestAlarmTypes = [];
    if (BaseStore.menu.getInfoByName("privateNetAlarm")) {
      requestAlarmTypes.push("5");
      this.activeKey = "5";
    }
    if (BaseStore.menu.getInfoByName("outsiderAlarm")) {
      requestAlarmTypes.push("2");
      this.activeKey = "2";
    }
    if (BaseStore.menu.getInfoByName("keyPersonnelAlarm")) {
      requestAlarmTypes.push("1");
      this.activeKey = "1";
    }
    this.requestAlarmTypes = requestAlarmTypes;
    this.listRef = this.requestAlarmTypes.map(v => React.createRef());
  }

  componentDidMount() {
    this.requestData().then(() => {
      SocketEmitter.on(SocketEmitter.eventName.alarm, this.handleAlarm);
      SocketEmitter.on(SocketEmitter.eventName.resolveAlarm, this.handleDoneAlarm);
    });
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.alarm, this.handleAlarm);
    SocketEmitter.off(SocketEmitter.eventName.resolveAlarm, this.handleDoneAlarm);
  }

  /**监听推送警情 */
  handleAlarm = result => {
    let { dataLists } = this.state;
    if (result.alarmType === "3") { // 魅影告警
      return;
    } else {
      // this.setState({ laoding: true });
      if (_.isEmpty(dataLists)) {
        dataLists[result.alarmType] = [result];
      } else {
        let list = dataLists[result.alarmType];
        if(list && !list.find(v => v.id === result.id)) {
          dataLists[result.alarmType].unshift(result);
        }
      }
      this.setState({ dataLists }, () => {
        // this.setState({ laoding: false });
        let ref = this.listRef[result.alarmType === "5" ? 2 : result.alarmType - 1];
        ref.current && ref.current.infinitRef.current.forceUpdateGrid();
      });
    }
  };

  /**监听处理滤除处理后的警情 */
  handleDoneAlarm = result => {
    let { dataLists } = this.state;
    if (result.alarmType === "3") {
      return;
    } else {
      let v = dataLists[result.alarmType];
      const index = v.findIndex(v => v.id === result.id);
      if (index > -1) {
        v.splice(index, 1);
      }
      this.setState({ dataLists }, () => {
        let ref = this.listRef[result.alarmType === "5" ? 2 : result.alarmType - 1];
        ref.current && ref.current.infinitRef.current.forceUpdateGrid();
      });
    }
  };

  /**跳转页面*/
  handlePageJump = id => {
    const { tab } = this.props;
    const { dataLists } = this.state;
    let activeKey = this.activeKey;
    let moduleName = this.getModuleName(activeKey).name.replace(/History/, "Detail");
    let searchData = {
      offset: 0,
      limit: 0,
      alarmTypes: [activeKey],
      alarmOperationType: 2,
      sortType: 1
    };
    let data = {
      id: id,
      libType: activeKey === "5" ? 4 : activeKey * 1,
      logTypes: "2",
      // threshold: activeKey === "1" ? "60.0" : "",
      searchData,
      isRealAlarm: true
    };
    let list = dataLists[activeKey];
    tab.openDetailPage({
      moduleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add('parameter', {
          id: data.id.toString(),
          libType: data.libType,
          isRealAlarm: true,
          searchData,
          list
        })
      }
    });
  };

  /**刷新 */
  freshen = () => {
    this.requestData(this.activeKey);
  };

  /**请求滚动条数据 */
  requestData = activeKey => {
    let { dataLists } = this.state;
    let option = {
      offset: 0,
      limit: 24,
      alarmOperationType: 2,
      includeDeletedTasks: false
    };
    let requestArr = [];
    if (activeKey) {
      requestArr = [Service.alarmResult.queryAlarmResults({ ...option, alarmTypes: [activeKey] })];
    } else {
      requestArr = this.requestAlarmTypes.map(v => Service.alarmResult.queryAlarmResults({ ...option, alarmTypes: [v] }));
    }
    return Promise.all(requestArr).then(resArr => {
      if (activeKey) {
        // 获取指定类型的警情列表
        let list = resArr[0].data.list ? resArr[0].data.list : [];
        dataLists[list[0] && list[0].alarmType] = list;
      } else {
        this.requestAlarmTypes.map((v, k) => {
          let res = resArr[k];
          let list = res.data.list ? res.data.list : [];
          dataLists[v] = list;
        });
      }
      this.setState({ dataLists, loading: false }, () => {
        this.listRef.map(v => v.current && v.current.infinitRef.current.forceUpdateGrid());
      });
    });
  };
  handleTableKey = key => {
    this.activeKey = key;
    this.forceUpdate();
  };
  handleButtonClick = type => {
    const { tab, location } = this.props;
    let moduleName = this.getModuleName(type).name;
    tab.goPage({ moduleName, location });
  };

  getModuleName = type => {
    if (type == "1") {
      return {
        name: "keyPersonnelHistory",
        icon: "icon-S_Bar_PeopleFocus",
        title: this.alarmLabel.keyPerson.alarmLabel
      };
    } else if (type == "2") {
      return {
        name: "outsiderHistory",
        icon: "icon-L_AID_Temporary",
        title: this.alarmLabel.outsider.alarmLabel
      };
    } else {
      return {
        name: "privateNetHistory",
        icon: "icon-S_Bar_PeopleMachine",
        title: "专网套件告警"
      };
    }
  };

  getTabContent = key => {
    const { dataLists, loading, infinitKey } = this.state;
    let module = this.getModuleName(key);
    let list = dataLists[key] && dataLists[key].length > 0 ? dataLists[key].slice(0, 24) : [];
    return (
      <>
        {list.length > 0 ? (
          <div className="alarm-tabs-tabscreate" ref="scrollH">
            <RealAlarmList
              ref={this.listRef[key === "5" ? 2 : key - 1]}
              key={module.name}
              cardType={key === "1" ? "KeyPointCard" : "ForeignCard"}
              list={list}
              itemWidth={key === "1" ? 340 : 174}
              itemHeight={key === "1" ? 322 : key === "2" ? 300 : 322}
              pdWidth={key === "1" ? 8 : 120}
              libType={key === "5" ? 4 : key}
              infinitKey={infinitKey}
              handlePageJump={this.handlePageJump}
            />
          </div>
        ) : loading ? (
          <Loading loading={loading} />
        ) : (
          <NoData imgType={2} title={"告警数据"} />
        )}
      </>
    );
  };
  getTabHeader = key => {
    let module = this.getModuleName(key);
    return (
      <span style={{ fontSize: "16px" }}>
        <IconFont type={module.icon} />
        <span style={{ fontSize: "14px" }}>{module.title}</span>
      </span>
    );
  };

  render() {
    const { dataLists, loading } = this.state;

    return (
      <div className="person-alarm-view">
        <div className="person-alarm-left">
          <AlarmEcharts alarmLabel={this.alarmLabel} requestAlarmTypes={this.requestAlarmTypes} />
        </div>
        <div className="person-alarm-right">
          <div className="alarm-just-button">
            <RefreshButton onClick={this.freshen} loading={loading} size={"default"} />
            <AuthComponent actionName={this.getModuleName(this.activeKey).name}>
              <Button className="more" onClick={this.handleButtonClick.bind(this, this.activeKey)}>
                查看更多
              </Button>
            </AuthComponent>
          </div>
          <Tabs type="card" onChange={this.handleTableKey} activeKey={this.activeKey}>
            {BaseStore.menu.getInfoByName("keyPersonnelAlarm") && (
              <TabPane tab={this.getTabHeader("1")} key="1">
                {!_.isEmpty(dataLists) ? this.getTabContent("1") : <Loading loading={loading} />}
              </TabPane>
            )}
            {BaseStore.menu.getInfoByName("outsiderAlarm") && (
              <TabPane tab={this.getTabHeader("2")} key="2">
                {!_.isEmpty(dataLists) ? this.getTabContent("2") : <Loading loading={loading} />}
              </TabPane>
            )}
            {BaseStore.menu.getInfoByName("privateNetAlarm") && (
              <TabPane tab={this.getTabHeader("5")} key="5">
                {!_.isEmpty(dataLists) ? this.getTabContent("5") : <Loading loading={loading} />}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default RealAlarmView;
